import React from 'react';

export default ({ containerRef, strokeStyle='rgba(255, 255, 255, .4)' }) => {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (containerRef.current) {
            const canvas = ref.current;
            canvas.width = containerRef.current.getBoundingClientRect().width;
            canvas.height = containerRef.current.getBoundingClientRect().height;
            const ctx = canvas.getContext('2d');
            const bubbles = [];
            const bubbleCount = 20;
            const bubbleSpeed = 1;
            function animate() {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.beginPath();
                for (let i = 0; i < bubbles.length; i++) {
                    bubbles[i].position.x = Math.sin(bubbles[i].count/bubbles[i].distanceBetweenWaves) * 50 + bubbles[i].xOff;
                    bubbles[i].position.y = bubbles[i].count;
                    bubbles[i].render();
                    if (bubbles[i].count < 0 - bubbles[i].radius) {
                        bubbles[i].count = canvas.height + bubbles[i].yOff;
                    } 
                    else {
                        bubbles[i].count -= bubbleSpeed;
                    }
                }
                window.requestAnimationFrame(animate);
            }
            window.requestAnimationFrame(animate);
            const createBubble = function() {
                this.position = {x: 0, y: 0};
                this.radius = 8 + Math.random() * 6;
                this.xOff = Math.random() * canvas.width - this.radius;
                this.yOff = Math.random() * canvas.height;
                this.distanceBetweenWaves = 50 + Math.random() * 40;
                this.count = canvas.height + this.yOff;
                this.maxRotation = 85;
                this.rotation = Math.floor(Math.random() * (this.maxRotation - (this.maxRotation * -1))) + (this.maxRotation * -1);
                this.rotationDirection = 'forward';
                this.resetPosition = function() {
                    this.position = {x: 0, y: 0};
                    this.radius = 8 + Math.random() * 6;
                    this.xOff = Math.random() * canvas.width - this.radius;
                    this.yOff = Math.random() * canvas.height;
                    this.distanceBetweenWaves = 50 + Math.random() * 40;
                    this.count = canvas.height + this.yOff;
                }
                this.render = function() {
                    if (this.rotationDirection === 'forward') {
                        if (this.rotation < this.maxRotation) {
                            this.rotation++;
                        } 
                        else {
                            this.rotationDirection = 'backward';
                        }
                    } 
                    else {
                        if (this.rotation > this.maxRotation * -1) {
                            this.rotation--;
                        } 
                        else {
                            this.rotationDirection = 'forward';
                        }
                    }
                    ctx.save();
                    ctx.translate(this.position.x, this.position.y);
                    ctx.rotate(this.rotation * Math.PI/180);
                    ctx.beginPath();
                    ctx.strokeStyle = strokeStyle;
                    ctx.lineWidth = 1;
                    ctx.arc(0, 0, this.radius - 3, 0, Math.PI*1.5, true);
                    ctx.stroke();
                    ctx.beginPath();
                    ctx.arc(0, 0, this.radius, 0, Math.PI*2, false);
                    ctx.stroke();
                    ctx.restore();
                }
            }
            for (let i = 0; i < bubbleCount; i++) {
                var tempBubble = new createBubble();
                bubbles.push(tempBubble);
            }
        }
    }, [containerRef, strokeStyle]);
    return (
        <canvas ref={ref} aria-label="bubbles" />
    )
}




