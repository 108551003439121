import React from "react"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Services from "../components/Services"
import Showcase from "../components/Showcase"
import About from "../components/About"
import Contact from "../components/Contact"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    speed: 700,
    speedAsDuration: true,
  })
}

const IndexPage: React.FC = () => {
  return (
    <Layout
      title="Custom Websites and Web Applications for Small Businesses"
      description="Wavefoundry creates custom websites and web applications for small businesses - we'd love to discuss your next project with you."
    >
      <Navbar />
      <Header />
      <Services />
      <About />
      <Showcase />
      <Contact />
      <Footer additionalClassName="landing-page" />
    </Layout>
  )
}

export default IndexPage
