import React from "react"
import CanvasBubbles from "./CanvasBubbles"
import ContactUsForm from "./ContactUsForm"
import SectionHeader from "./SectionHeader"
import Turtle from "./Turtle"

const WaveSVG = (
  <svg
    viewBox="0 0 400 600"
    width="100%"
    preserveAspectRatio="none"
    height="100%"
  >
    <path
      d="M475.7 80.52C469.07 66.5 374.45 14.94 293.37 8.7C255.63 5.79 220.29 7.12 187.33 12.67C157.44 17.7 128.53 27.4 101.65 41.4C77.14 54.17 111.02 36.52 103.22 40.59C31.73 77.84 -2.07 161.54 23.52 237.98C31.29 261.19 37.7 280.35 52.36 324.12C58.92 343.71 60.13 364.7 55.88 384.92C48.82 418.47 46.37 430.07 42.37 449.08C31.81 499.28 74.55 544.62 125.29 537.04C147.51 533.72 142.61 534.46 173.12 529.9C212.91 523.95 253.14 537.33 281.45 565.91C308.25 592.96 345.14 600.39 392.14 588.19C505.26 558.83 518.08 170.16 475.7 80.52Z"
      fill="#94deff"
    />
  </svg>
)

export default () => {
  const svgRef = React.useRef(null)
  return (
    <section className="blue-section contact-us-section">
      <div ref={svgRef} className="hide-mobile side-wave-svg-container">
        {WaveSVG}
        <CanvasBubbles containerRef={svgRef} strokeStyle="#f5f8ff" />
      </div>
      <div className="grid-container">
        <div className="grid-item-2">
          <div className="layout-left-container">
            <SectionHeader
              header="contact us"
              text="Interested in working with us? Send us a message!"
              id="contact"
              alt
            />
            <div className="paper padding-2 margin-top-4">
              <ContactUsForm />
            </div>
          </div>
        </div>
        <div className="grid-item-2 hide-mobile">
          <div className="padding-2">
            <div className="contact-turtle-container">
              <Turtle index={2} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
