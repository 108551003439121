import React from "react"
import { withStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import { indigo } from "@material-ui/core/colors"

const PhoneTextField = withStyles({
  root: {
    "& legend span": {
      fontSize: 9.5,
    },
    "& label.Mui-focused": {
      color: indigo[700],
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 9px) scale(1)",
      fontSize: 14,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: indigo[700],
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#c4c4c4",
      },
      "&.Mui-focused fieldset": {
        borderColor: indigo[700],
      },
    },
    "& input": {
      cursor: "default !important",
      padding: "8px 12px",
      fontSize: 14,
    },
  },
})(TextField)

interface PhoneInputProps {
  label: string
  focused: boolean
  value: string
}

const PhoneInput: React.FC<PhoneInputProps> = ({ label, focused, value }) => {
  return (
    <PhoneTextField
      label={label}
      focused={focused}
      variant="outlined"
      inputProps={{ readOnly: true }}
      fullWidth
      value={value}
    />
  )
}

export default PhoneInput
