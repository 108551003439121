import React from "react"
import ShowcaseImages from "./ShowcaseImages"
import SectionHeader from "./SectionHeader"
import WaveSVGTop from "./WaveSVGTop"
import WaveSVGBottom from "./WaveSVGBottom"
import { Link } from "gatsby"
import Icon from "./Icon"

const checkmark = (
  <div className="checkmark flex-center-center">
    <Icon iconName="check" width={16} />
  </div>
)

const tillItems = [
  <>
    Custom integration with{" "}
    <a
      href="https://optimoroute.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      OptimoRoute
    </a>{" "}
    to assist with driving logistics and order delivery
  </>,
  <>
    Content management system for blogs and recipes with an integrated cart for
    seamless ingredient purchases
  </>,
  <>Customer referral program to improve user engagement and growth</>,
]
const dzignStudioItems = [
  <>Custom order request + customer invoicing feature</>,
  <>Ability for users to upload images and provide detailed personalizations</>,
  <>Custom order tracking and carrier tracking</>,
]

export default () => {
  return (
    <section className="wave-section wave-top wave-bottom">
      <div className="wave-svg-container wave-top">
        <WaveSVGTop />
      </div>
      <div className="layout-container">
        <SectionHeader
          header="featured projects"
          id="showcase"
          text="We're proud of the work we do and the businesses our clients build. Check out some of the companies and organizations we've worked with in the past."
        />
      </div>
      <div className="showcase-container">
        <div className="grid-container">
          <div className="grid-item-2">
            <ShowcaseImages leftSide />
          </div>
          <div className="grid-item-2">
            <div className="showcase-content till right">
              <h1>till</h1>
              <p>
                Till is a food delivery service located in Birmingham, Alabama
                which allows customers to order locally-sourced produce, meats
                and pantry items from local farms, delivered right to their
                doorstep. Customers can search for food from over 20 artisan
                farmers and learn about the origins of each farm. Till currently
                has a userbase of over 650 customers which grows every week.
              </p>
              <p>
                Wavefoundry began working with till in early 2020, helping them
                rebuild their site from the ground up. We migrated their web
                operations and data from a WooCommerce site to a custom
                Wavefoundry platform, greatly enhancing user experience, farmer
                transparency and customer satisfaction.
              </p>
              <h6>Cool Features</h6>
              <ul className="cool-features-list">
                {tillItems.map((item, index) => {
                  return (
                    <li className="flex-align-center" key={`till-${index}`}>
                      <div>{checkmark}</div>
                      <div>{item}</div>
                    </li>
                  )
                })}
              </ul>
              <div>
                <a
                  className="btn primary-outline-btn full-width-mobile"
                  href="https://usetill.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  VIEW WEBSITE
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="showcase-container">
        <div className="grid-container row-reverse">
          <div className="grid-item-2">
            <ShowcaseImages />
          </div>
          <div className="grid-item-2">
            <div className="showcase-content dzign-studio left">
              <h1>dzign studio</h1>
              <p>
                Dzign Studio is a husband and wife duo specializing in custom
                poured acrylic, industrial frames, and woodwork. Located in
                Pittsburgh, Pennsylvania, Dzign Studio builds and sells handmade
                creations for weddings and home decor. They began selling their
                products in early 2020 to local friends and family, but later
                started shipping across state lines with the help of the
                internet...and Wavefoundry.
              </p>
              <p>
                When selling products on Etsy just wasn’t cutting it,
                Wavefoundry started working with Dzign Studio in July of 2020
                with a blank slate. We designed and built a shiny new website
                from scratch, offering a vast array of product customization
                features to help cover the broad spectrum of product options
                available from this online boutique.
              </p>
              <h6>Cool Features</h6>
              <ul className="cool-features-list">
                {dzignStudioItems.map((item, index) => {
                  return (
                    <li className="flex-align-center" key={`till-${index}`}>
                      <div>{checkmark}</div>
                      <div>{item}</div>
                    </li>
                  )
                })}
              </ul>
              <div>
                <a
                  className="btn primary-outline-btn full-width-mobile"
                  href="https://shopdzignstudio.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  VIEW WEBSITE
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="layout-container">
        <div className="footer-text">
          <p>
            We love learning about our partners' unique business needs and
            building the software to suit them. We take pride in every pixel!
          </p>
          <Link
            to="/project-showcase/"
            className="btn primary-btn full-width-mobile"
          >
            VIEW ALL PROJECTS
          </Link>
        </div>
      </div>
      <div className="wave-svg-container wave-bottom">
        <WaveSVGBottom />
      </div>
    </section>
  )
}
