import React from "react"
import { IconProps } from "../types"
import gsap from "gsap"

const initBarTransform = "translate3d(26px, 20px,0) rotate(-45deg)"

const SEOIcon: React.FC<IconProps> = ({ color }) => {
  const circle = React.useRef<HTMLDivElement>(null)
  const bar = React.useRef<HTMLDivElement>(null)
  const icon = React.useRef<HTMLDivElement>(null)
  const text = React.useRef<HTMLSpanElement>(null)
  const timeline = gsap.timeline({ repeat: -1, repeatDelay: 3 })

  React.useEffect(() => {
    timeline.to(
      icon.current,
      {
        transform: "scale3d(0.5, 0.5, 0.5) translate3d(21px, 56px, 0)",
        duration: 0.5,
        ease: "sine.inOut",
      },
      0
    )
    timeline.to(
      circle.current,
      {
        width: 110,
        duration: 0.5,
        ease: "sine.inOut",
      },
      0
    )
    timeline.to(
      bar.current,
      {
        transform: "translate3d(18px, 7px,0) rotate(0deg)",
        height: 14,
        width: 2,
        duration: 0.5,
        ease: "sine.inOut",
      },
      0
    )
    timeline.to(
      text.current,
      {
        opacity: 1,
        y: 2,
        ease: "sine.inOut",
        duration: 0.2,
        delay: 0.3,
      },
      0
    )
    timeline.to(
      bar.current,
      {
        opacity: 0,
        duration: 0.2,
      },
      1
    )
    timeline.to(
      bar.current,
      {
        opacity: 1,
        duration: 0.2,
      },
      2
    )
    timeline.to(
      bar.current,
      {
        opacity: 0,
        duration: 0.2,
      },
      3
    )
    timeline.to(
      bar.current,
      {
        opacity: 1,
        duration: 0.2,
      },
      4
    )
    timeline.to(
      icon.current,
      {
        transform: "scale3d(1, 1, 1) translate3d(30px, 26px, 0)",
        duration: 0.5,
        ease: "sine.inOut",
      },
      5
    )
    timeline.to(
      circle.current,
      {
        width: 24,
        duration: 0.5,
        ease: "sine.inOut",
      },
      5
    )
    timeline.to(
      bar.current,
      {
        transform: initBarTransform,
        height: 16,
        width: 4,
        duration: 0.5,
        ease: "sine.inOut",
      },
      5
    )
    timeline.to(
      text.current,
      {
        opacity: 0,
        ease: "sine.inOut",
        duration: 0.2,
        y: -2,
        delay: 0,
      },
      5
    )
  }, [])

  return (
    <div style={{ position: "absolute", width: 100, height: 100 }}>
      <div
        style={{
          position: "absolute",
          transformOrigin: "50% 50%",
          width: 40,
          height: 40,
          zIndex: 10,
          transform: "scale3d(1, 1, 1) translate3d(30px, 26px, 0)",
        }}
        ref={icon}
      >
        <div
          style={{
            width: 24,
            height: 24,
            borderRadius: 50,
            border: `4px solid ${color[500]}`,
            position: "absolute",
            top: 2,
            left: 2,
          }}
          ref={circle}
        />
        <div
          style={{
            height: 16,
            width: 4,
            borderRadius: 1,
            background: color[500],
            position: "absolute",
            transform: initBarTransform,
          }}
          ref={bar}
        />
      </div>
      <span
        ref={text}
        style={{
          position: "absolute",
          display: "block",
          fontSize: 8,
          color: color[500],
          top: 26,
          left: 0,
          right: 0,
          textAlign: "center",
          opacity: 0,
        }}
      >
        Google
      </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 220 220"
        style={{ position: "absolute", width: 80, top: 10, left: 10 }}
      >
        <g fill={color[500]}>
          <path d="M133.15 178.57C132.29 178.57 131.59 179.27 131.59 180.14C131.59 180.14 131.59 180.2 131.59 180.21C131.59 183.43 128.97 186.05 125.75 186.05C122.71 186.05 98.4 186.05 95.36 186.05C92.14 186.05 89.52 183.43 89.52 180.21C89.52 180.2 89.52 180.14 89.52 180.14C89.52 179.27 88.82 178.57 87.95 178.57C79.92 178.57 15.7 178.57 7.67 178.57C4.44 178.57 1.82 181.19 1.82 184.41C1.82 185.33 1.82 192.62 1.82 193.53C1.82 196.76 4.44 199.38 7.67 199.38C28.24 199.38 192.86 199.38 213.44 199.38C216.66 199.38 219.28 196.76 219.28 193.53C219.28 192.62 219.28 185.33 219.28 184.41C219.28 181.19 216.66 178.57 213.44 178.57C197.38 178.57 141.18 178.57 133.15 178.57ZM198.18 191.92C196.93 191.92 186.92 191.92 185.67 191.92C184.08 191.92 182.77 190.63 182.77 189.02C182.77 189.01 182.77 188.96 182.77 188.95C182.77 187.36 184.06 186.05 185.67 186.05C186.92 186.05 196.93 186.05 198.18 186.05C199.77 186.05 201.07 187.34 201.07 188.95C201.07 188.95 201.07 188.98 201.07 189.02C200.22 190.95 199.25 191.92 198.18 191.92Z" />
          <path d="M20.94 17.47C9.65 17.47 0.44 27.1 0.44 38.91C0.44 49.89 0.44 137.73 0.44 148.71C0.44 160.52 9.65 170.15 20.94 170.15C38.8 170.15 181.64 170.15 199.5 170.15C210.79 170.15 220 160.52 220 148.71C220 137.73 220 49.89 220 38.91C220 27.1 210.79 17.47 199.5 17.47C163.79 17.47 38.8 17.47 20.94 17.47ZM199.5 151.78C181.64 151.78 38.8 151.78 20.94 151.78C19.33 151.78 18.01 150.4 18.01 148.71C18.01 137.73 18.01 49.89 18.01 38.91C18.01 37.22 19.33 35.85 20.94 35.85C38.8 35.85 181.64 35.85 199.5 35.85C201.11 35.85 202.43 37.22 202.43 38.91C202.43 46.23 202.43 82.83 202.43 148.71C201.55 150.76 200.57 151.78 199.5 151.78Z" />
        </g>
      </svg>
    </div>
  )
}

export default SEOIcon
