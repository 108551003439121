import React from "react"
import gsap from "gsap"
import { IconProps } from "../types"

const bars = [
  {
    from:
      "M60.28 125C61.5 125 62.5 126 62.5 127.22C62.5 135.68 62.5 158.81 62.5 167.26C62.5 168.49 61.5 169.48 60.28 169.48C56.97 169.48 49.28 169.48 45.97 169.48C44.75 169.48 43.75 168.49 43.75 167.26C43.75 158.81 43.75 135.68 43.75 127.22C43.75 126 44.75 125 45.97 125C49.28 125 56.97 125 60.28 125Z",
    to:
      "M58.4 87.5C60.66 87.5 62.5 89.34 62.5 91.6C62.5 107.18 62.5 149.81 62.5 165.38C62.5 167.65 60.66 169.48 58.4 169.48C55.47 169.48 50.78 169.48 47.85 169.48C45.59 169.48 43.75 167.65 43.75 165.38C43.75 149.81 43.75 107.18 43.75 91.6C43.75 89.34 45.59 87.5 47.85 87.5C50.78 87.5 55.47 87.5 58.4 87.5Z",
  },
  {
    from:
      "M96.53 100C98.44 100 100 101.56 100 103.47C100 116.68 100 152.81 100 166.01C100 167.93 98.44 169.48 96.53 169.48C93.47 169.48 87.78 169.48 84.72 169.48C82.81 169.48 81.25 167.93 81.25 166.01C81.25 152.81 81.25 116.68 81.25 103.47C81.25 101.56 82.81 100 84.72 100C87.78 100 93.47 100 96.53 100Z",
    to:
      "M94.65 62.5C97.61 62.5 100 64.89 100 67.85C100 88.18 100 143.81 100 164.13C100 167.09 97.61 169.48 94.65 169.48C91.97 169.48 89.28 169.48 86.6 169.48C83.64 169.48 81.25 167.09 81.25 164.13C81.25 143.81 81.25 88.18 81.25 67.85C81.25 64.89 83.64 62.5 86.6 62.5C89.28 62.5 91.97 62.5 94.65 62.5Z",
  },
  {
    from:
      "M132.87 76.82C135.43 76.82 137.5 78.9 137.5 81.46C137.5 99.06 137.5 147.24 137.5 164.85C137.5 167.41 135.43 169.48 132.87 169.48C130.04 169.48 126.21 169.48 123.38 169.48C120.82 169.48 118.75 167.41 118.75 164.85C118.75 147.24 118.75 99.06 118.75 81.46C118.75 78.9 120.82 76.82 123.38 76.82C126.21 76.82 130.04 76.82 132.87 76.82Z",
    to:
      "M131.25 37.5C134.7 37.5 137.5 40.3 137.5 43.75C137.5 68.9 137.5 138.09 137.5 163.23C137.5 166.68 134.7 169.48 131.25 169.48C128.75 169.48 127.5 169.48 125 169.48C121.55 169.48 118.75 166.68 118.75 163.23C118.75 138.09 118.75 68.9 118.75 43.75C118.75 40.3 121.55 37.5 125 37.5C127.5 37.5 128.75 37.5 131.25 37.5Z",
  },
  {
    from:
      "M169.45 59.23C172.52 59.23 175 61.71 175 64.78C175 85.86 175 143.58 175 164.67C175 167.73 172.52 170.22 169.45 170.22C166.81 170.22 164.44 170.22 161.8 170.22C158.73 170.22 156.25 167.73 156.25 164.67C156.25 143.58 156.25 85.86 156.25 64.78C156.25 61.71 158.73 59.23 161.8 59.23C164.44 59.23 166.81 59.23 169.45 59.23Z",
    to:
      "M168.75 15.85C172.2 15.85 175 18.65 175 22.1C175 51.72 175 134.34 175 163.97C175 167.42 172.2 170.22 168.75 170.22C166.25 170.22 165 170.22 162.5 170.22C159.05 170.22 156.25 167.42 156.25 163.97C156.25 134.34 156.25 51.72 156.25 22.1C156.25 18.65 159.05 15.85 162.5 15.85C165 15.85 166.25 15.85 168.75 15.85Z",
  },
]

const GraphIcon: React.FC<IconProps> = ({ color }) => {
  const groupRef = React.useRef<SVGGElement>(null)
  React.useEffect(() => {
    if (groupRef.current) {
      groupRef.current.childNodes.forEach((node, index) => {
        if (index !== 0) {
          gsap.to(node, {
            attr: {
              d: bars[index - 1].to,
            },
            duration: 1.2,
            repeat: -1,
            yoyo: true,
            yoyoEase: "sine",
            delay: index * 0.5 - 3,
          })
        }
      })
    }
  }, [groupRef])
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 200 200"
      className="main-svg"
    >
      <g ref={groupRef}>
        <path
          fill={color[900]}
          d="M26.86 175.63C113.2 175.63 167.15 175.63 188.74 175.63C191.51 175.63 193.75 177.87 193.75 180.64C193.75 182.39 193.75 186.76 193.75 193.75C97.36 193.75 37.11 193.75 13.01 193.75C9.28 193.75 6.25 190.72 6.25 186.99C6.25 163.72 6.25 105.56 6.25 12.5C15.19 12.5 20.78 12.5 23.02 12.5C25.14 12.5 26.86 14.22 26.86 16.35C26.86 37.58 26.86 90.68 26.86 175.63Z"
        />
        {bars.map((bar, index) => {
          return (
            <path
              fill={color[500 + index * 100]}
              key={`bar-${index}`}
              d={bar.from}
            />
          )
        })}
      </g>
    </svg>
  )
}

export default GraphIcon
