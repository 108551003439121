import React from 'react';
import gsap from 'gsap';
import { IconProps } from '../types';

const BAR_FROM = "M129.7 35.84C132.81 36.99 134.41 40.44 133.26 43.55C124.41 67.62 99.92 134.18 91.07 158.24C89.93 161.35 86.47 162.95 83.36 161.8C80.49 160.75 77.51 159.65 74.64 158.6C71.53 157.45 69.94 154 71.08 150.89C79.93 126.82 104.42 60.26 113.28 36.2C114.42 33.09 117.87 31.49 120.98 32.64C123.85 33.69 126.83 34.79 129.7 35.84Z";
const BAR_TO = "M175.01 140.49C175.02 143.72 172.41 146.34 169.18 146.34C147.85 146.39 89.7 146.51 68.37 146.56C65.15 146.57 62.53 143.96 62.52 140.73C62.51 137.64 62.51 134.21 62.5 131.12C62.49 127.89 65.1 125.27 68.33 125.26C89.66 125.22 147.81 125.09 169.14 125.05C172.36 125.04 174.98 127.65 174.99 130.88C175 133.97 175 137.4 175.01 140.49Z";
const ease = "sine.inOut";
const CodeIcon: React.FC<IconProps> = ({ color }) => {
    const barRef = React.useRef<SVGPathElement>(null);
    const leftChevronRef = React.useRef<SVGPathElement>(null);
    const rightChevronRef = React.useRef<SVGPathElement>(null);
    const timeline = gsap.timeline({ repeat: -1, repeatDelay: 2.5 });
    React.useEffect(() => {
        timeline.to(barRef.current, {
            attr: {
                d: BAR_TO
            },
            ease,
            duration: 0.5,
        }, 0)
        timeline.to(leftChevronRef.current, {
            rotate: 180,
            transformOrigin: '50% 50%',
            ease,
            duration: 0.5,
        }, 0),
        timeline.to(rightChevronRef.current, {
            opacity: 0,
            x: 40,
            duration: 0.5
        }, 0)
        timeline.to(
            barRef.current, 
            {
                opacity: 0,
                yoyo: true,
                repeat: 4,
                duration: .5
            }, 1)
        timeline.to(barRef.current, {
            opacity: 1,
            attr: {
                d: BAR_FROM
            },
            ease,
            duration: 0.5,
        }, 3)
        timeline.to(leftChevronRef.current, {
            rotate: 0,
            transformOrigin: '50% 50%',
            ease,
            duration: 0.5
        }, 3),
        timeline.to(rightChevronRef.current, {
            opacity: 1,
            x: 0,
            duration: 0.5,
            ease
        }, 3)
    }, [])
    return (
        <svg viewBox="0 0 200 200" className='main-svg'>
            <g fill={color[800]}>
                <path ref={leftChevronRef} d="M33.02 100C51.38 81.84 61.59 71.75 63.63 69.74C64.28 69.09 64.65 68.21 64.65 67.29C64.65 66.37 64.28 65.49 63.63 64.84C62.71 63.93 55.38 56.69 54.47 55.78C53.81 55.13 52.92 54.77 51.99 54.77C51.06 54.77 50.17 55.13 49.52 55.78C45.29 59.96 11.5 93.37 7.28 97.55C5.91 98.9 5.91 101.09 7.28 102.45C11.5 106.62 45.29 140.04 49.51 144.22C50.17 144.87 51.06 145.23 51.99 145.23C52.92 145.23 53.81 144.87 54.47 144.22C55.38 143.31 62.71 136.07 63.62 135.16C64.99 133.81 64.99 131.62 63.62 130.26C59.54 126.23 49.34 116.14 33.02 100Z" />
                <path ref={barRef} d={BAR_FROM} />
                <path ref={rightChevronRef} d="M150.49 55.76C149.17 54.46 146.85 54.46 145.54 55.76C144.62 56.66 137.29 63.91 136.38 64.81C135.01 66.17 135.01 68.36 136.38 69.71C138.42 71.73 148.62 81.82 166.98 99.98C148.62 118.14 138.42 128.23 136.38 130.25C135.01 131.6 135.01 133.79 136.38 135.14C137.3 136.05 144.62 143.29 145.54 144.2C146.19 144.85 147.09 145.22 148.01 145.22C148.94 145.22 149.83 144.85 150.49 144.2C154.71 140.02 188.5 106.61 192.72 102.43C194.09 101.08 194.09 98.88 192.72 97.53C184.28 89.18 154.71 59.94 150.49 55.76Z" />
            </g>
        </svg>
    )
}

export default CodeIcon;