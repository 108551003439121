/// <reference path="../all.d.ts" />

import React from "react"
import Chart from "chart.js/dist/Chart"

const type = "line"

const ChartWrapper: React.FC<{ height: number; data: any; options: any }> = ({
  height,
  data,
  options,
}) => {
  const [chart, setChart] = React.useState<any>(null)
  const canvasRef = React.useRef<HTMLCanvasElement>(null)
  React.useEffect(() => {
    if (canvasRef && canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d")
      const newChart = new Chart(ctx, {
        type,
        data,
        options,
      })
      setChart(newChart)
    }
  }, [canvasRef])
  React.useEffect(() => {
    if (chart) {
      const newData = data.datasets[0].data.slice(0, 12);
      chart.data.datasets[0].data = newData;
      chart.update();
    }
  }, [chart, data])
  return (
    <canvas ref={canvasRef} width="100%" height={height} aria-label="Chart" />
  )
}

export default ChartWrapper;
