import React from "react"
import gsap from "gsap"
import { IconProps } from "../types"

const LINE_2_FROM =
  "M56.41 107.05C56.32 107.05 56.25 107.13 56.25 107.22C56.25 108.71 56.25 113.05 56.25 114.54C56.25 114.63 56.32 114.7 56.41 114.7C57.14 114.7 59.18 114.7 59.91 114.7C60 114.7 60.07 114.63 60.07 114.54C60.07 113.05 60.07 108.71 60.07 107.22C60.07 107.13 60 107.05 59.91 107.05C59.18 107.05 57.14 107.05 56.41 107.05Z"
const LINE_2_TO =
  "M60.08 107.05C57.96 107.05 56.25 108.77 56.25 110.88C56.25 111.64 56.25 110.11 56.25 110.88C56.25 112.99 57.96 114.7 60.08 114.7C78.06 114.7 128.19 114.7 146.17 114.7C148.29 114.7 150 112.99 150 110.88C150 110.11 150 111.64 150 110.88C150 108.77 148.29 107.05 146.17 107.05C128.19 107.05 78.06 107.05 60.08 107.05Z"
const LINE_1_FROM =
  "M56.43 77.7C56.34 77.71 56.27 77.78 56.27 77.87C56.27 79.37 56.27 83.71 56.27 85.2C56.27 85.29 56.34 85.36 56.43 85.35C57.16 85.27 59.18 85.06 59.91 84.99C60 84.98 60.07 84.9 60.07 84.81C60.07 83.31 60.07 78.98 60.07 77.48C60.07 77.4 59.99 77.33 59.91 77.34C59.18 77.41 57.16 77.62 56.43 77.7Z"
const LINE_1_TO =
  "M60.52 77.7C58.4 77.71 56.7 79.43 56.71 81.54C56.71 82.3 56.7 80.77 56.71 81.54C56.71 83.65 58.43 85.36 60.55 85.35C78.44 85.27 128.3 85.06 146.19 84.99C148.3 84.98 150.01 83.26 150 81.15C150 80.38 150 81.91 150 81.15C149.99 79.03 148.27 77.33 146.16 77.34C128.26 77.41 78.41 77.62 60.52 77.7Z"
const PAPER_LENGTH = 70
const LINE_HEIGHT = 30

const DesignIcon: React.FC<IconProps> = ({ color }) => {
  const pencil = React.useRef<SVGPathElement>(null)
  const line1 = React.useRef<SVGPathElement>(null)
  const line2 = React.useRef<SVGPathElement>(null)
  const timeline = gsap.timeline({ repeat: -1 })
  React.useEffect(() => {
    timeline.to(
      pencil.current,
      {
        x: PAPER_LENGTH,
        duration: 1,
      },
      0
    )
    timeline.to(
      line1.current,
      {
        attr: {
          d: LINE_1_TO,
        },
        opacity: 1,
        duration: 1,
      },
      0
    )
    timeline.to(
      pencil.current,
      {
        x: 0,
        y: LINE_HEIGHT,
        duration: 1,
      },
      1
    )
    timeline.to(
      pencil.current,
      {
        x: PAPER_LENGTH,
        duration: 1,
      },
      2
    )
    timeline.to(
      line2.current,
      {
        attr: {
          d: LINE_2_TO,
        },
        opacity: 1,
        duration: 1,
      },
      2
    )
    timeline.to(
      pencil.current,
      {
        rotate: 180,
        transformOrigin: "50% 50%",
      },
      3
    )
    timeline.to(
      pencil.current,
      {
        x: 0,
        duration: 1,
      },
      4
    )
    timeline.to(
      line2.current,
      {
        attr: {
          d: LINE_2_FROM,
        },
        opacity: 0,
        duration: 1,
      },
      4
    )
    timeline.to(
      pencil.current,
      {
        y: 0,
        x: PAPER_LENGTH,
        duration: 1,
      },
      5
    )
    timeline.to(
      pencil.current,
      {
        x: 0,
        duration: 1,
      },
      6
    )
    timeline.to(
      line1.current,
      {
        attr: {
          d: LINE_1_FROM,
        },
        opacity: 0,
        duration: 1,
      },
      6
    )
    timeline.to(
      pencil.current,
      {
        rotate: 0,
        transformOrigin: "50% 50%",
      },
      7
    )
  }, [])
  return (
    <svg className="main-svg" viewBox="0 0 200 200">
      <g>
        <path
          fill={color[400]}
          d="M121.64 25C121.8 23.98 121.88 22.93 121.88 21.88C121.88 9.8 112.07 0 100 0C87.93 0 78.13 9.8 78.13 21.88C78.13 22.93 78.2 23.98 78.36 25C74.9 25 47.21 25 43.75 25C33.4 25 25 33.4 25 43.75C25 57.5 25 167.5 25 181.25C25 191.6 33.4 200 43.75 200C55 200 145 200 156.25 200C166.6 200 175 191.6 175 181.25C175 167.5 175 57.5 175 43.75C175 33.4 166.6 25 156.25 25C149.33 25 125.1 25 121.64 25ZM109.38 21.88C109.38 27.07 105.2 31.25 100 31.25C94.8 31.25 90.63 27.07 90.63 21.88C90.63 16.68 94.8 12.5 100 12.5C105.2 12.5 109.38 16.68 109.38 21.88ZM162.5 181.25C162.5 184.69 159.69 187.5 156.25 187.5C145 187.5 55 187.5 43.75 187.5C40.31 187.5 37.5 184.69 37.5 181.25C37.5 167.5 37.5 57.5 37.5 43.75C37.5 40.31 40.31 37.5 43.75 37.5C45 37.5 51.25 37.5 62.5 37.5C62.5 42.19 62.5 44.79 62.5 45.31C62.5 47.89 64.61 50 67.19 50C73.75 50 126.25 50 132.81 50C135.39 50 137.5 47.89 137.5 45.31C137.5 44.79 137.5 42.19 137.5 37.5C148.75 37.5 155 37.5 156.25 37.5C159.69 37.5 162.5 40.31 162.5 43.75C162.5 71.25 162.5 153.75 162.5 181.25Z"
        />
        <path
          id="pencil"
          fill={color[800]}
          ref={pencil}
          d="M120.2 38.8C119.53 39.47 118.46 39.47 117.79 38.8C116.22 37.23 103.65 24.66 102.08 23.09C101.42 22.43 101.42 21.35 102.08 20.69C102.73 20.03 107.95 14.81 108.61 14.16C111.25 11.51 115.56 11.51 118.22 14.16C119.07 15.01 125.87 21.82 126.72 22.67C129.39 25.31 129.39 29.62 126.72 32.28C125.42 33.58 120.85 38.15 120.2 38.8ZM59.3 63.46C57.5 73.78 56.5 79.51 56.3 80.66C55.89 82.98 57.92 84.99 60.24 84.6C61.38 84.39 67.12 83.39 77.44 81.58C99.74 59.28 112.13 46.89 114.61 44.41C115.27 43.74 115.27 42.67 114.61 42C113.04 40.43 100.47 27.86 98.9 26.29C98.22 25.63 97.14 25.63 96.48 26.29C91.52 31.25 79.13 43.64 59.3 63.46ZM75.5 72.18L75.5 77.32L66.37 78.92L61.97 74.52L63.56 65.39L68.7 65.39L68.7 72.18L75.5 72.18Z"
        />
        <path
          fill={color[600]}
          ref={line1}
          d={LINE_1_FROM}
          style={{ opacity: 0 }}
        />
        <path
          fill={color[600]}
          ref={line2}
          d={LINE_2_FROM}
          style={{ opacity: 0 }}
        />
        <use xlinkHref="#pencil" />
      </g>
    </svg>
  )
}

export default DesignIcon
