import React from "react"
import underline from "../images/underline.svg"

const SectionHeader: React.FC<{
  text?: string
  header: string
  id: string
  alt?: boolean
}> = ({ text, header, alt, id }) => {
  return (
    <div className={`section-header${alt ? " alt" : ""}`} id={id}>
      <h1>{header}</h1>
      <img src={underline} width={280} height={15} />
      {text && <p>{text}</p>}
    </div>
  )
}

export default SectionHeader
