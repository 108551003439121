import React from "react"
import HeaderBackgroundSVG from "./HeaderBackgroundSVG"
import Turtle from "./Turtle"
import CanvasBubbles from "./CanvasBubbles"

const heading = "we build custom websites and apps for small businesses"
const subheading =
  "Discover how partnering with Wavefoundry can increase your online sales and improve the digital presence of your business."

export default () => {
  const headerRef = React.useRef<HTMLElement>(null)
  return (
    <header ref={headerRef}>
      <HeaderBackgroundSVG />
      <div className="content-container">
        <div className="layout-container">
          <div className="content-grid grid-container">
            <div className="grid-item-2">
              <div className="width-container">
                <h1>{heading}</h1>
                <p>{subheading}</p>
              </div>
            </div>
            <div className="grid-item-2">
              <div className="turtle-container">
                <Turtle index={1} />
              </div>
            </div>
          </div>
        </div>
        <div className="landing-header-svg-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            preserveAspectRatio="none"
            viewBox="0 0 1200 200"
            fill="currentColor"
          >
            <path d="M0 150C35.86 163.64 101.42 167.68 196.68 162.12C339.57 153.79 427.16 94.62 569.83 94.72C712.5 94.83 738.58 117.08 895.69 118.6C1000.43 119.61 1101.87 98.76 1200 56.06L1200 200L0 200" />
          </svg>
        </div>
      </div>
      <CanvasBubbles containerRef={headerRef} />
    </header>
  )
}
