import React from "react"
import { IconProps } from "../types"
import gsap from "gsap"

const UserIcon: React.FC<IconProps> = ({ color }) => {
  const cogRef = React.useRef<SVGPathElement>(null)
  React.useEffect(() => {
    gsap.to(cogRef.current, {
      rotation: "360",
      repeat: -1,
      transformOrigin: "50% 50%",
      duration: 3,
    })
  }, [])
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 200 200"
      className="main-svg"
    >
      <g>
        <path
          fill={color[500]}
          d="M127.12 56.25C127.12 32.08 107.93 12.5 84.26 12.5C60.59 12.5 41.4 32.08 41.4 56.25C41.4 80.42 60.59 100 84.26 100C107.93 100 127.12 80.42 127.12 56.25ZM108.67 110.94C101.24 114.42 92.97 116.41 84.26 116.41C75.56 116.41 67.32 114.42 59.85 110.94C59.29 110.94 54.82 110.94 54.26 110.94C29.42 110.94 9.26 131.51 9.26 156.87C9.26 158.3 9.26 169.67 9.26 171.09C9.26 180.15 16.46 187.5 25.33 187.5C37.12 187.5 131.4 187.5 143.19 187.5C152.06 187.5 159.26 180.15 159.26 171.09C159.26 169.67 159.26 158.3 159.26 156.87C159.26 131.51 139.1 110.94 114.26 110.94C113.14 110.94 109.23 110.94 108.67 110.94Z"
        />
        <path
          ref={cogRef}
          fill={color[800]}
          d="M187.03 156.35C187.84 152.14 187.84 147.82 187.03 143.61C187.83 143.17 194.29 139.6 195.1 139.15C196.03 138.64 196.45 137.59 196.14 136.61C194.04 130.15 190.46 124.31 185.77 119.44C185.05 118.7 183.88 118.52 182.97 119.03C182.16 119.47 175.7 123.04 174.89 123.49C171.5 120.7 167.6 118.54 163.37 117.12C163.37 116.23 163.37 109.1 163.37 108.21C163.37 107.2 162.63 106.31 161.59 106.09C154.63 104.6 147.51 104.67 140.89 106.09C139.85 106.31 139.11 107.2 139.11 108.21C139.11 109.1 139.11 116.25 139.11 117.14C134.9 118.57 131 120.73 127.58 123.51C126.78 123.06 120.33 119.49 119.53 119.04C118.6 118.54 117.44 118.7 116.72 119.46C112.04 124.31 108.46 130.15 106.36 136.63C106.03 137.61 106.47 138.66 107.4 139.17C108.21 139.61 114.67 143.18 115.47 143.63C114.66 147.84 114.66 152.16 115.47 156.37C114.67 156.82 108.21 160.39 107.4 160.83C106.47 161.34 106.05 162.39 106.36 163.37C108.46 169.83 112.04 175.68 116.72 180.54C117.44 181.28 118.62 181.46 119.53 180.96C120.34 180.51 126.8 176.94 127.6 176.49C131 179.29 134.9 181.45 139.13 182.86C139.13 183.75 139.13 190.9 139.13 191.79C139.13 192.8 139.87 193.69 140.91 193.91C147.87 195.4 154.99 195.33 161.61 193.91C162.65 193.69 163.39 192.8 163.39 191.79C163.39 190.9 163.39 183.75 163.39 182.86C167.6 181.43 171.5 179.27 174.91 176.49C175.72 176.94 182.18 180.51 182.99 180.96C183.92 181.46 185.07 181.3 185.79 180.54C190.48 175.69 194.06 169.85 196.16 163.37C196.45 162.37 196.03 161.32 195.1 160.81C193.49 159.92 187.83 156.8 187.03 156.35ZM136.08 149.98C136.08 141.98 142.88 135.47 151.24 135.47C159.6 135.47 166.4 141.98 166.4 149.98C166.4 157.98 159.6 164.5 151.24 164.5C142.88 164.5 136.08 157.98 136.08 149.98Z"
        />
      </g>
    </svg>
  )
}

export default UserIcon
