import firebase from 'firebase/app';
import 'firebase/functions';
import { FIREBASE_CREDENTIALS } from './config';

let instance: any = null;
export default function getFirebase(): any {
  if (typeof window !== 'undefined') {
    if (instance) {
        return instance;
    }
    instance = firebase.initializeApp(FIREBASE_CREDENTIALS);
    return instance;
  }
  return null;
}
