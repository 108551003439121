import React from 'react';
import Icon from './Icon';
import ChartWrapper from './ChartWrapper';

function getCurrency(num: number) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const chartHeight = 120;
const buttons = ['3m', '6m', '1y'];
const grossVolumeInitialData = [1035, 1105, 1531, 2001, 1553, 2215, 2512, 2623, 3023, 2621, 3023, 3532, 3526, 4215, 3567, 3820, 4296, 3684, 4255, 4596, 5136, 4185, 5351, 6235, 5316, 5232, 6395, 7323, 6230, 8325, 6363, 7352, 6246, 7892, 8215, 7296, 8256, 8526, 7529, 7900, 8520, 8850, 9231, 7346, 8965, 8521, 7923, 8672, 9520, 8523, 9613, 9825];
const newCustomerInitialData = [5, 5, 2, 14, 6, 8, 12, 8, 1, 12, 5, 17, 5, 12, 17, 19, 18, 13, 1, 10, 2, 18, 21, 13, 6, 26, 4, 14, 8, 3, 5, 10, 29, 9, 4, 34, 41, 3, 27, 38, 2, 42, 44, 10, 15, 20, 21, 44, 48, 41, 49, 44]

function get6mData(arr: number[]) {
    const newArr: number[] = [];
    let sum = 0;
    arr.forEach((item, index) => {
		sum += item;
        if (index % 2 !== 0) {
            newArr.push(sum);
            sum = 0;
		}
	});
	return newArr;
}
function get1yData(arr: number[]) {
    const newArr: number[] = [];
    let sum = 0;
    arr.forEach((item, index) => {
		sum += item;
        if (index % 4 === 0 && index !== 0) {
            newArr.push(sum);
            sum = 0;
		}
	});
	return newArr;
}
const chartData = [
    {
        labels: ['Oct 11', 'Oct 18', 'Oct 25', 'Nov 1', 'Nov 8', 'Nov 15', 'Nov 22', 'Nov 29', 'Dec 6', 'Dec 13', 'Dec 20', 'Dec 27'],
        grossVolume: grossVolumeInitialData.slice(-12),
        newCustomers: newCustomerInitialData.slice(-12)
    },
    {
        labels: ['Jul 26', 'Aug 9', 'Aug 23', 'Sep 6', 'Sep 20', 'Oct 4', 'Oct 18', 'Nov 1', 'Nov 15', 'Nov 29', 'Dec 13', 'Dec 27'],
        grossVolume: get6mData(grossVolumeInitialData.slice(28)),
        newCustomers: get6mData(newCustomerInitialData.slice(28))
    },
    {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        grossVolume: get1yData(grossVolumeInitialData),
        newCustomers: get1yData(newCustomerInitialData)
    }
]
const chartOptions = {
    maintainAspectRatio: false,
    legend: {
        display: false
    },
    elements: {
        point: {
            radius: 0
        }
    },
    scales: {
        yAxes: [{
            gridLines: {
                display: true,
                drawTicks: false
            },
            ticks: {
                display: false,
            }
        }],
        xAxes: [
            {
                gridLines: {
                    display: false
                },
                ticks: {
                    display: true,
                    autoSkip: true,
                    maxTicksLimit: 6,
                }
            }
        ]
    },
    tooltips: {
        enabled: false
    }
}

const blue1 = '#4d95e3';
const blue2 = '#0d47a1';

export default () => {
    const [activeButton, setActiveButton] = React.useState(2);
    React.useEffect(() => {
        const interval = setInterval(() => {
            setActiveButton(val => {
                if (val === buttons.length - 1) {
                    return 0;
                }
                return val + 1;
            })
        }, 6000);
        return () => clearInterval(interval);
    }, []);
    const grossVolumeData = {
        labels: chartData[activeButton].labels,
        datasets: [{ 
            data: chartData[activeButton].grossVolume.slice(0, 12),
            borderColor: blue1,
            fill: false
          }
        ]
    };
    const newCustomerData = {
        labels: chartData[activeButton].labels,
        datasets: [{ 
            data: chartData[activeButton].newCustomers.slice(0, 12),
            borderColor: blue2,
            fill: false
          }
        ]
    };
    const totalGrossVolume = getCurrency(chartData[activeButton].grossVolume.reduce((a, b) => a+b, 0));
    const totalNewCustomers = chartData[activeButton].newCustomers.reduce((a, b) => a+b, 0)
    return (
        <figure className="dashboard">
            <div className="header flex-space-between-center">
                <div>
                    <div className="logo quipquotes-logo">
                        <span>QUIP</span><span>QUOTES</span>
                    </div>
                </div>
                <div className="header-actions flex-align-center">
                    <div className="search-container">
                        <div className="search flex-align-center">
                            <Icon iconName="search" width={20} />
                        </div>
                    </div>
                    <div className="icon-container">
                        <Icon iconName="cog" width={20} />
                    </div>
                    <div className="icon-container">
                        <Icon iconName="user" width={20} />
                    </div>
                    <div className="icon-container">
                        <Icon iconName="logout" width={20} />
                    </div>
                </div>
            </div>
            <div className="analytics-container">
                <div className="analytics-header flex-space-between-center">
                    <div>
                        <h6>Analytics</h6>
                    </div>
                    <div className="button-group-container">
                        <div className="button-wrapper">
                            <div className="button-group">
                                {buttons.map((button, index) => {
                                    return <div className={`button${index === activeButton ? ' active' : ''}`} key={button}><span>{button}</span></div>
                                })}
                            </div>
                        </div>
                        <div className="button-wrapper">
                            <div className="button-group">
                                <div className={`button${activeButton !== 2 ? ' active' : ''}`}>
                                    <span>Weekly</span>
                                </div>
                                <div className={`button${activeButton === 2 ? ' active' : ''}`}>
                                    <span>Monthly</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="chart-header flex-space-between-center">
                    <div>
                        <h6 className="title">Gross Volume</h6>
                    </div>
                    <div>
                        <h6 style={{color: blue1}}>${totalGrossVolume}</h6>
                    </div>
                </div>
                <div className="chart-container">
                    <ChartWrapper data={grossVolumeData} height={chartHeight} options={chartOptions} />
                </div>
                <div className="chart-header flex-space-between-center">
                    <div>
                        <h6 className="title">New Customers</h6>
                    </div>
                    <div>
                        <h6 style={{color: blue2}}>{totalNewCustomers}</h6>
                    </div>
                </div>
                <div className="chart-container">
                    <ChartWrapper data={newCustomerData} height={chartHeight} options={chartOptions} />
                </div>
            </div>
        </figure>
    )
}