export const FIREBASE_CREDENTIALS = {
    apiKey: "AIzaSyDsFlDvm3zfzWQebThom1Oy8bdBjHCwfVA",
    authDomain: "wavefoundry-site.firebaseapp.com",
    databaseURL: "https://wavefoundry-site.firebaseio.com",
    projectId: "wavefoundry-site",
    storageBucket: "wavefoundry-site.appspot.com",
    messagingSenderId: "338869934712",
    appId: "1:338869934712:web:c46ee31b68a5dfa646ab8d"
};

export const GOOGLE_RECAPTCHA_SITE_KEY = '6LfhXd8ZAAAAAH8VXLUNNLz-WBTp1hKaZKbFOQcM';