import React from "react"

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 860 300"
      fill="currentColor"
    >
      <path d="M534.51 23.28C534.11 25.05 534.02 33.18 534.21 41.31C534.51 54.54 534.7 56.5 536.56 58.95C540.58 64.43 543.52 65.12 562.43 65.12C577.13 65.12 580.17 64.83 583.69 63.26C590.16 60.22 590.85 58.06 590.85 39.15C590.85 13.29 590.36 12.8 563.9 12.5C554.2 12.41 544.89 12.7 543.13 13.29C539.31 14.37 535.29 19.07 534.51 23.28Z" />
      <path d="M644.93 262.45C649.93 272.45 659.43 280.38 671.48 284.6C679.13 287.34 701.56 288.42 714.6 286.65C722.73 285.58 733.9 282.64 736.64 280.87C737.3 280.42 735.6 267.29 731.55 241.49C725.37 241.49 721.95 241.49 721.26 241.49C715.67 241.49 709.31 241 707.15 240.31C701.96 238.74 697.16 234.04 695.78 229.24C695 226.49 694.71 194.16 694.71 120.67C694.71 113.71 694.71 78.86 694.71 16.13L667.27 16.13L639.84 16.13L639.84 131.35C639.77 211.83 641.47 255.53 644.93 262.45Z" />
      <path d="M767.21 262.75C770.84 270 779.46 278.42 787.2 282.05C797.19 286.75 804.15 287.83 822.57 287.24C838.93 286.65 848.34 285.28 856.08 282.15C856.34 282.04 857.65 281.52 860 280.58L853.34 241.49C847.34 241.49 844.01 241.49 843.34 241.49C837.86 241.49 831.68 241 829.72 240.51C825.71 239.33 820.81 235.61 818.85 232.28C817.97 230.71 817.57 205.23 817.18 123.42C817.15 116.3 816.99 80.7 816.69 16.62L789.06 16.32L761.33 16.13L761.33 129.49C761.27 210.16 763.23 254.58 767.21 262.75Z" />
      <path d="M394.21 95.26C390.56 95.61 388.54 95.81 388.13 95.85C384.7 96.14 377.26 96.63 371.48 97.02C370.77 97.06 367.24 97.26 360.89 97.61L360.89 115.15L360.89 132.69L377.55 132.69L394.21 132.69C394.21 165.73 394.21 184.08 394.21 187.75C394.21 249.29 394.4 251.15 400.68 263C406.75 274.57 416.45 282.11 429.97 285.54C438.5 287.7 458.29 288.19 471.61 286.52C479.75 285.44 497.29 281.72 499.05 280.64C499.93 280.15 499.54 275.74 497.68 263.4C496.21 254.28 494.84 245.66 494.54 244.19C494.51 244.02 494.35 243.17 494.05 241.64C483.94 241.41 478.32 241.28 477.2 241.25C457.9 240.96 454.96 239.98 451.53 233.12C449.67 229.4 449.57 226.95 449.27 180.9C449.25 177.68 449.16 161.61 448.98 132.69L475.53 132.69L501.99 132.69L501.99 114.07L501.99 95.46L475.53 95.46L449.08 95.46L449.08 69L449.08 42.55L421.64 42.55L394.21 42.55L394.21 68.9L394.21 95.26Z" />
      <path d="M535 283.62L562.92 283.62L590.85 283.62L590.85 189.56L590.85 95.49L562.92 95.49L535 95.49L535 189.56L535 283.62Z" />
      <path d="M9.23 44.46L71.9 113.2L79.22 105.45L14.74 38.64L20.1 33.01L84.99 100.24L91.05 93.83L26.18 26.6L32.08 20.37L96.97 87.6L104.17 80.01L37.21 14.93L42.78 9.05C79.78 38.9 100.33 55.48 104.44 58.8C104.44 58.8 106.68 60.76 107.45 61.57C115.92 70.34 119.6 82.29 118.45 93.79C121.23 138.58 158.21 148.29 163.73 154.01C169.84 160.38 264.1 258.04 264.1 258.04C264.1 258.04 264.09 258.06 264.09 258.06C264.16 258.14 264.24 258.16 264.32 258.24C270.1 264.23 270.12 273.99 264.41 280.01C258.71 286.05 249.35 286.09 243.6 280.13C243.52 280.03 243.48 279.95 243.42 279.88C243.42 279.88 243.4 279.9 243.4 279.9C243.4 279.9 150.3 183.46 143.19 176.09C136.79 169.42 121.57 129.47 82.35 129.22C82.31 129.21 82.2 129.19 82.15 129.19C72.92 129.5 63.6 126.35 56.15 119.6C55.62 119.12 54.41 117.83 54.41 117.83C50.99 113.37 33.9 91.06 3.14 50.92L9.23 44.46Z" />
      <path d="M284.2 19.48C264.56 1.46 230.46 8.88 197.96 44.3C169.66 75.16 174.55 102.48 186.74 121.5C157.31 148.03 108.48 189.29 80.98 219.27C33.3 271.25 38.53 276.26 45.11 282.3C51.7 288.34 57.14 293.12 104.82 241.14C132.32 211.17 175.86 160.82 199.76 129.22C219.76 139.72 240.77 140.39 269.08 109.54C301.57 74.12 303.83 37.49 284.2 19.48Z" />
    </svg>
  )
}
