import React from "react"
import { Formik, Form, Field, FieldProps } from "formik"
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import MuiTextField from "@material-ui/core/TextField"
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import { blue } from "@material-ui/core/colors"
import useFirebase from "../useFirebase"
import { GOOGLE_RECAPTCHA_SITE_KEY } from "../config"
import Icon from "./Icon"

declare var window: any;

const TextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: blue[700],
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: blue[700],
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: blue[700],
      },
    },
  },
})(MuiTextField)

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Nunito Sans"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
})

const SuccessDialog: React.FC<{
  dialogOpen: boolean
  setDialogOpen: (open: boolean) => void
}> = ({ dialogOpen, setDialogOpen }) => {
  function handleClose() {
    setDialogOpen(false)
  }
  return (
    <Dialog open={dialogOpen} onClose={handleClose}>
      <div className="success-dialog padding-2">
        <div className="success-icon flex-center-center">
          <Icon iconName="check" width={50} />
        </div>
        <div className="content-container">
          <h5 className="text-primary">Success!</h5>
          <p>
            We have successfully received your message - we will get back to you
            as soon as we can!
          </p>
          <div>
            <button onClick={handleClose} className="btn white-btn full-width">
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const ContactUsForm = () => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [focused, setFocused] = React.useState(false);
  function handleFocus() {
    if (!focused) {
      setFocused(true);
    }
  }
  React.useEffect(() => {
    if (focused) {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${GOOGLE_RECAPTCHA_SITE_KEY}`;
      document.body.appendChild(script);
      return () => {document.body.removeChild(script)};
    }
  }, [focused])
  const firebase = useFirebase();
  return (
    <ThemeProvider theme={theme}>
      <div className="form-container">
        <span className="form-header">Say hello or ask us anything</span>
        <Formik
          initialValues={{ name: "", email: "", message: "" }}
          validate={({ name, email, message }) => {
            const errors: { [key: string]: string } = {}
            if (!name) {
              errors.name = "Please enter your name"
            }
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
              errors.email = "Please enter a valid email address"
            }
            if (!message) {
              errors.message = "Please enter a message"
            }
            return errors
          }}
          onSubmit={(values, { setStatus, setSubmitting, resetForm }) => {
            setStatus(null)
            const contactUs = firebase.functions().httpsCallable("contactUs")
            window.grecaptcha.ready(function() {
              window.grecaptcha
                .execute(GOOGLE_RECAPTCHA_SITE_KEY, { action: "CONTACT_US" })
                .then((recaptchaToken: string) => {
                  return contactUs({ ...values, recaptchaToken })
                })
                .then(() => {
                  setDialogOpen(true)
                  resetForm()
                })
                .catch(() => {
                  setStatus(`An error occurred - please try again later.`);
                  setSubmitting(false);
                })
            })
          }}
        >
          {({ status, isSubmitting }) => {
            return (
              <Form>
                <Field name="name">
                  {({ field, meta }: FieldProps) => {
                    const hasError = Boolean(meta.touched && meta.error)
                    return (
                      <div className="input-container">
                        <TextField
                          {...field}
                          label="Full name"
                          placeholder="First and last name"
                          fullWidth
                          variant="outlined"
                          error={hasError}
                          helperText={hasError ? meta.error : ""}
                          onFocus={handleFocus}
                        />
                      </div>
                    )
                  }}
                </Field>
                <Field name="email">
                  {({ field, meta }: FieldProps) => {
                    const hasError = Boolean(meta.touched && meta.error)
                    return (
                      <div className="input-container">
                        <TextField
                          {...field}
                          label="Email"
                          type="email"
                          placeholder="you@example.com"
                          fullWidth
                          variant="outlined"
                          error={hasError}
                          helperText={hasError ? meta.error : ""}
                          onFocus={handleFocus}
                        />
                      </div>
                    )
                  }}
                </Field>
                <Field name="message">
                  {({ field, meta }: FieldProps) => {
                    const hasError = Boolean(meta.touched && meta.error)
                    return (
                      <div className="input-container">
                        <TextField
                          {...field}
                          label="Message"
                          placeholder="How can we help you?"
                          fullWidth
                          variant="outlined"
                          multiline
                          rows={6}
                          error={hasError}
                          helperText={hasError ? meta.error : ""}
                          onFocus={handleFocus}
                        />
                      </div>
                    )
                  }}
                </Field>
                <div>
                  <div className="custom-recaptcha">
                    <span>This form is protected by reCAPTCHA</span>
                    <div className="flex-align-center">
                      <div>
                        <a href="https://policies.google.com/privacy">
                          Privacy
                        </a>
                      </div>
                      <div>
                        <a href="https://policies.google.com/terms">Terms</a>
                      </div>
                    </div>
                  </div>
                </div>
                {status && (
                  <div className="margin-bottom-1 text-error">{status}</div>
                )}
                <div>
                  <button
                    type="submit"
                    className={`btn primary-btn submit-btn full-width-mobile${
                      isSubmitting ? " disabled" : ""
                    }`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <div className="saving">
                        SUBMITTING<span>.</span>
                        <span>.</span>
                        <span>.</span>
                      </div>
                    ) : (
                      "SUBMIT"
                    )}
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
      <SuccessDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
    </ThemeProvider>
  )
}

export default ContactUsForm;
