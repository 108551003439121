import React from "react"
import gsap from "gsap"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage, { FluidObject } from "gatsby-image"
import Icon from "./Icon"
import TillIcon from "./TillIcon"
import DzignStudioIcon from "./DzignStudioIcon"

interface Product {
  label: string
  price: string
  image: FluidObject
}

const delayIncrement = 0.08
export default () => {
  const [activeCompany, setActiveCompany] = React.useState<
    "till" | "dzignStudio"
  >("till")
  const navRef = React.useRef<HTMLDivElement>(null)
  const dividerRef = React.useRef<HTMLSpanElement>(null)
  const headerRef = React.useRef<HTMLDivElement>(null)
  const headerTextRef = React.useRef<HTMLHeadingElement>(null)
  const gridRef = React.useRef<HTMLDivElement>(null)
  function handleComplete() {
    setActiveCompany(company => (company === "till" ? "dzignStudio" : "till"))
  }
  const timeline = gsap.timeline({ repeat: -1, repeatDelay: 6 })
  const ease = "sine"
  React.useEffect(() => {
    if (gridRef.current) {
      timeline.fromTo(
        navRef.current,
        { opacity: 1, y: 0, ease },
        { opacity: 0, y: -40, ease, onComplete: handleComplete },
        0
      )
      timeline.fromTo(
        dividerRef.current,
        { width: 60, ease },
        { width: 0, ease },
        0
      )
      timeline.fromTo(
        headerTextRef.current,
        { opacity: 1, ease },
        { opacity: 0, ease },
        0
      )
      gridRef.current.childNodes.forEach((node: any, index) => {
        timeline.fromTo(
          node,
          { y: 0, opacity: 1, delay: index * delayIncrement, ease },
          { y: 50, opacity: 0, delay: index * delayIncrement, ease },
          0
        )
      })
      timeline.fromTo(
        navRef.current,
        { opacity: 0, y: -40, ease },
        { opacity: 1, y: 0, ease },
        1
      )
      timeline.fromTo(
        dividerRef.current,
        { width: 0, ease },
        { width: 60, ease },
        1
      )
      timeline.fromTo(
        headerTextRef.current,
        { opacity: 0, ease },
        { opacity: 1, ease },
        1
      )
      gridRef.current.childNodes.forEach((node: any, index) => {
        timeline.fromTo(
          node,
          { y: 50, opacity: 0, delay: index * delayIncrement, ease },
          { y: 0, opacity: 1, delay: index * delayIncrement, ease },
          1
        )
      })
    }
  }, [])

  const imageData = useStaticQuery(graphql`
    query {
      product1: file(relativePath: { eq: "apples.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product2: file(relativePath: { eq: "peppers.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product3: file(relativePath: { eq: "potatoes.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product4: file(relativePath: { eq: "pumpkins.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product5: file(relativePath: { eq: "october.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product6: file(relativePath: { eq: "harvest.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const tillProducts: Product[] = [
    {
      label: "Gala Apples",
      price: "$5.00",
      image: imageData.product1.childImageSharp.fluid,
    },
    {
      label: "Hot Peppers",
      price: "$6.50",
      image: imageData.product2.childImageSharp.fluid,
    },
    {
      label: "Russet Potatoes",
      price: "$5.50",
      image: imageData.product3.childImageSharp.fluid,
    },
  ]
  const dzignStudioProducts: Product[] = [
    {
      label: "Pumpkin Picking",
      price: "$25.00",
      image: imageData.product4.childImageSharp.fluid,
    },
    {
      label: "October Colors",
      price: "$15.00",
      image: imageData.product5.childImageSharp.fluid,
    },
    {
      label: "Harvest Wishes",
      price: "$30.00",
      image: imageData.product6.childImageSharp.fluid,
    },
  ]
  let activeProducts: Product[]
  let navItems: JSX.Element
  let bodyHeader: string
  let productHeader: string
  if (activeCompany === "till") {
    activeProducts = tillProducts
    navItems = (
      <>
        <div className="icon-container flex-align-center">
          <TillIcon />
        </div>
        <div className="nav-links flex-align-center">
          <div className="padding-small">
            <span>FRESH FOOD</span>
          </div>
          <div className="padding-small">
            <span>FOOD SOURCES</span>
          </div>
        </div>
      </>
    )
    bodyHeader = "FIND FRESH FOOD"
    productHeader = "Shop Local Produce"
  } else {
    activeProducts = dzignStudioProducts
    navItems = (
      <>
        <div className="icon-container flex-align-center">
          <DzignStudioIcon />
        </div>
        <div className="nav-links flex-align-center">
          <div className="padding-small">
            <span>WEDDINGS</span>
          </div>
          <div className="padding-small">
            <span>HOME DECOR</span>
          </div>
        </div>
      </>
    )
    bodyHeader = "HANDMADE CUSTOM CREATIONS"
    productHeader = "Shop Fall Decor"
  }
  return (
    <figure className="tablet">
      <div className="tablet-screen">
        <div className={`tablet-nav-container flex-align-center`} ref={navRef}>
          <div
            className={`tablet-nav flex-space-between-center ${activeCompany}`}
          >
            <div className="flex-align-center">{navItems}</div>
            <div className="nav-buttons flex-align-center">
              <div className="padding-1">
                <Icon iconName="search" width={16} />
              </div>
              <div className="padding-1">
                <Icon iconName="cart" width={16} />
              </div>
              <div className="padding-1">
                <Icon iconName="menuBars" width={16} />
              </div>
            </div>
          </div>
        </div>
        <div className="tablet-body">
          <div className="tablet-body-content">
            <h6 className={`product-header`} ref={headerTextRef}>
              {productHeader}
            </h6>
            <span
              className={`product-header-divider ${activeCompany}`}
              ref={dividerRef}
            />
            <div className="grid-container" ref={gridRef}>
              {activeProducts.map((product, index) => {
                return (
                  <div key={`product-${index}`} className="grid-item-3-always">
                    <div className="product-padding">
                      <div className="product-container">
                        <div className="image-container">
                          <GatsbyImage
                            fluid={{ ...product.image, aspectRatio: 1 }}
                          />
                        </div>
                        <div className="product-content">
                          <div>
                            <span className="text-truncate">
                              {product.label}
                            </span>
                          </div>
                          <div className="price">
                            <span>{product.price}</span>
                          </div>
                          <div>
                            <div className={`cart-btn ${activeCompany}`}>
                              ADD TO CART
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </figure>
  )
}
