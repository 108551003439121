import React from "react"
import { IconProps } from "../types"
import gsap from "gsap"

const BAG_TO =
  "M56.08 50.83L90.49 50.83C98.14 37.94 102.39 30.78 103.23 29.35C104.55 27.13 104.02 24.13 102.04 22.65C101.34 22.12 100.51 21.84 99.66 21.84C94.38 21.84 52.19 21.84 46.92 21.84C44.54 21.84 42.62 24 42.62 26.67C42.62 27.62 42.87 28.56 43.34 29.35C45.04 32.22 49.28 39.38 56.08 50.83ZM56.08 60.5C1.66 95.36 4.48 141.65 4.48 147.49C4.48 163.5 17.68 176.49 33.97 176.49C41.83 176.49 104.74 176.49 112.6 176.49C128.89 176.49 142.09 163.5 142.09 147.49C142.09 141.75 144.61 95.16 90.49 60.5C83.61 60.5 59.52 60.5 56.08 60.5ZM77.97 149.71C77.96 151.15 76.91 152.32 75.63 152.31C75.16 152.31 71.41 152.31 70.95 152.31C69.66 152.32 68.61 151.15 68.6 149.71C68.6 149.18 68.6 144.95 68.6 144.42C65.29 144.23 62.1 142.95 59.42 140.75C58.41 139.88 58.22 138.26 59 137.12C59.07 137.02 59.15 136.91 59.24 136.82C59.59 136.45 62.35 133.54 62.69 133.18C63.52 132.34 64.74 132.25 65.65 132.95C66.78 133.73 68.07 134.15 69.4 134.15C70.22 134.15 76.8 134.15 77.62 134.15C79.52 134.15 81.07 132.24 81.07 129.86C81.07 127.91 80.02 126.22 78.5 125.73C77.18 125.29 66.64 121.78 65.32 121.34C59.88 119.53 56.08 113.73 56.08 107.22C56.08 99.25 61.64 92.77 68.57 92.55C68.57 92.02 68.57 87.8 68.57 87.27C68.58 85.82 69.63 84.66 70.92 84.66C71.38 84.66 75.13 84.66 75.6 84.66C76.88 84.66 77.93 85.82 77.94 87.27C77.94 87.8 77.94 92.04 77.94 92.57C81.25 92.76 84.45 94.04 87.12 96.25C88.13 97.12 88.32 98.74 87.54 99.87C87.47 99.98 87.39 100.08 87.3 100.17C86.96 100.54 84.22 103.49 83.88 103.86C83.05 104.69 81.83 104.78 80.92 104.08C79.79 103.3 78.5 102.88 77.17 102.88C76.35 102.88 69.78 102.88 68.96 102.88C67.06 102.88 65.5 104.79 65.5 107.17C65.5 109.12 66.56 110.81 68.08 111.3C69.4 111.74 79.93 115.25 81.25 115.69C86.69 117.5 90.5 123.3 90.5 129.81C90.46 137.79 84.87 144.29 77.97 144.47C77.97 145.52 77.97 149.19 77.97 149.71Z"
const BAG_FROM =
  "M56.08 72.24L90.49 72.24C98.14 61.55 102.39 55.61 103.23 54.42C104.55 52.58 104.02 50.09 102.04 48.86C101.34 48.42 100.51 48.19 99.66 48.19C94.38 48.19 52.19 48.19 46.92 48.19C44.54 48.19 42.62 49.98 42.62 52.2C42.62 52.99 42.87 53.76 43.34 54.42C45.04 56.8 49.28 62.74 56.08 72.24ZM56.08 80.26C1.66 109.18 4.48 147.58 4.48 152.43C4.48 165.72 17.68 176.49 33.97 176.49C41.83 176.49 104.74 176.49 112.6 176.49C128.89 176.49 142.09 165.72 142.09 152.43C142.09 147.67 144.61 109.02 90.49 80.26C83.61 80.26 59.52 80.26 56.08 80.26ZM77.97 154.27C77.96 155.47 76.91 156.44 75.63 156.43C75.16 156.43 71.41 156.43 70.95 156.43C69.66 156.44 68.61 155.47 68.6 154.27C68.6 153.83 68.6 150.32 68.6 149.89C65.29 149.73 62.1 148.67 59.42 146.84C58.41 146.12 58.22 144.77 59 143.83C59.07 143.74 59.15 143.66 59.24 143.58C59.59 143.28 62.35 140.86 62.69 140.56C63.52 139.86 64.74 139.79 65.65 140.37C66.78 141.02 68.07 141.36 69.4 141.37C70.22 141.37 76.8 141.37 77.62 141.37C79.52 141.37 81.07 139.78 81.07 137.8C81.07 136.19 80.02 134.78 78.5 134.38C77.18 134.01 66.64 131.1 65.32 130.74C59.88 129.23 56.08 124.42 56.08 119.02C56.08 112.41 61.64 107.04 68.57 106.85C68.57 106.41 68.57 102.91 68.57 102.47C68.58 101.27 69.63 100.3 70.92 100.31C71.38 100.31 75.13 100.31 75.6 100.31C76.88 100.31 77.93 101.27 77.94 102.47C77.94 102.91 77.94 106.43 77.94 106.87C81.25 107.03 84.45 108.09 87.12 109.92C88.13 110.64 88.32 111.99 87.54 112.92C87.47 113.01 87.39 113.1 87.3 113.18C86.96 113.48 84.22 115.93 83.88 116.23C83.05 116.92 81.83 117 80.92 116.42C79.79 115.77 78.5 115.43 77.17 115.42C76.35 115.42 69.78 115.42 68.96 115.42C67.06 115.42 65.5 117.01 65.5 118.98C65.5 120.6 66.56 122 68.08 122.41C69.4 122.77 79.93 125.69 81.25 126.05C86.69 127.56 90.5 132.37 90.5 137.76C90.46 144.38 84.87 149.78 77.97 149.93C77.97 150.8 77.97 153.84 77.97 154.27Z"
const COINS_FROM =
  "M147.37 168.9C147.37 173.09 155.67 176.49 165.89 176.49C176.12 176.49 184.42 173.09 184.42 168.9C184.42 168.39 184.42 164.34 184.42 163.84C180.43 167.29 173.15 168.9 165.89 168.9C158.64 168.9 151.36 167.29 147.37 163.84C147.37 164.85 147.37 168.39 147.37 168.9ZM196.76 123.35C196.76 119.17 188.47 115.76 178.24 115.76C168.02 115.76 159.72 119.17 159.72 123.35C159.72 127.54 168.02 130.94 178.24 130.94C188.47 130.94 196.76 127.54 196.76 123.35ZM147.37 157.51C147.37 161.7 155.67 165.1 165.89 165.1C176.12 165.1 184.42 161.7 184.42 157.51C184.42 156.9 184.42 152.01 184.42 151.39C180.43 155.43 173.14 157.51 165.89 157.51C158.65 157.51 151.36 155.43 147.37 151.39C147.37 152.62 147.37 156.9 147.37 157.51ZM196.76 146.13C196.76 145.62 196.76 141.57 196.76 141.06C194.53 143.01 191.24 144.34 187.5 145.15C187.5 145.66 187.5 148.17 187.5 152.7C193.68 150.19 196.76 148 196.76 146.13ZM147.37 144.23C147.37 149.47 155.67 153.72 165.89 153.72C176.12 153.72 184.42 149.47 184.42 144.23C184.42 138.99 176.12 134.74 165.89 134.74C155.67 134.74 147.37 138.99 147.37 144.23ZM196.76 134.74C196.76 134.23 196.76 130.18 196.76 129.68C193.34 132.65 187.45 134.25 181.26 134.63C184.11 136.33 186.2 138.61 187.05 141.42C192.84 140.14 196.76 137.62 196.76 134.74Z"
const COINS_TO =
  "M147.37 171.43C147.37 174.22 155.67 176.49 165.89 176.49C176.12 176.49 184.42 174.22 184.42 171.43C184.42 171.1 184.42 168.4 184.42 168.06C180.43 170.36 173.15 171.43 165.89 171.43C158.64 171.43 151.36 170.36 147.37 168.06C147.37 168.73 147.37 171.1 147.37 171.43ZM196.76 141.09C196.76 138.3 188.47 136.03 178.24 136.03C168.02 136.03 159.72 138.3 159.72 141.09C159.72 143.88 168.02 146.15 178.24 146.15C188.47 146.15 196.76 143.88 196.76 141.09ZM147.37 163.85C147.37 166.64 155.67 168.91 165.89 168.91C176.12 168.91 184.42 166.64 184.42 163.85C184.42 163.44 184.42 160.18 184.42 159.77C180.43 162.46 173.14 163.85 165.89 163.85C158.65 163.85 151.36 162.46 147.37 159.77C147.37 160.59 147.37 163.44 147.37 163.85ZM196.76 156.26C196.76 155.92 196.76 153.23 196.76 152.89C194.53 154.18 191.24 155.07 187.5 155.61C187.5 155.95 187.5 157.62 187.5 160.64C193.68 158.97 196.76 157.51 196.76 156.26ZM147.37 155C147.37 158.49 155.67 161.32 165.89 161.32C176.12 161.32 184.42 158.49 184.42 155C184.42 151.51 176.12 148.68 165.89 148.68C155.67 148.68 147.37 151.51 147.37 155ZM196.76 148.68C196.76 148.34 196.76 145.64 196.76 145.3C193.34 147.29 187.45 148.35 181.26 148.61C184.11 149.74 186.2 151.25 187.05 153.13C192.84 152.27 196.76 150.6 196.76 148.68Z"

const MoneyIcon: React.FC<IconProps> = ({ color }) => {
  const bagRef = React.useRef<SVGPathElement>(null)
  const coinsRef = React.useRef<SVGPathElement>(null)
  React.useEffect(() => {
    gsap.to(bagRef.current, {
      attr: {
        d: BAG_TO,
      },
      repeat: -1,
      yoyo: true,
      yoyoEase: "sine",
      duration: 1,
    })
    gsap.to(coinsRef.current, {
      attr: {
        d: COINS_TO,
      },
      repeat: -1,
      delay: -1.5,
      yoyo: true,
      yoyoEase: "sine",
      duration: 1,
    })
  }, [])
  return (
    <svg
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
      className="main-svg"
    >
      <g>
        <path fill={color[800]} ref={bagRef} d={BAG_FROM} />
        <path fill={color[700]} ref={coinsRef} d={COINS_FROM} />
      </g>
    </svg>
  )
}

export default MoneyIcon
