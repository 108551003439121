import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import PhoneInput from "./PhoneInput"
import Icon from "./Icon"
import { wait } from "../utils"

const textFields = [
  { label: "Name", targetValue: "Jane Doe" },
  { label: "Email", targetValue: "jane@wavefoundry.io" },
  { label: "Credit Card", targetValue: "2323 4444 4321 9876" },
]
const initValues = textFields.map(() => ({ focused: false, value: "" }))
export default () => {
  const [trigger, setTrigger] = React.useState(true)
  const [activeButton, setActiveButton] = React.useState(false)
  const [activeIcons, setActiveIcons] = React.useState<boolean[]>([
    true,
    false,
    false,
  ])
  const [dialogActive, setDialogActive] = React.useState(false)
  const [textFieldData, setTextFieldData] = React.useState<
    { focused: boolean; value: string }[]
  >(initValues)
  function updateActiveIcon(index: number) {
    if (index < 2) {
      setActiveIcons([true, false, false])
    }
    if (index === 2) {
      setActiveIcons([false, true, false])
    } else if (index === 3) {
      setActiveIcons([false, false, true])
    }
  }
  const processFields = React.useCallback(async () => {
    await wait(() => null, 1000)
    for (const [i] of textFields.entries()) {
      await wait(() => {
        setTextFieldData(data => {
          const newData = data.map(val => ({ ...val, focused: false }))
          newData[i].focused = true
          return newData
        })
        updateActiveIcon(i)
      }, 500)
      for (const letter of textFields[i].targetValue.split("")) {
        await wait(() => {
          setTextFieldData(data => {
            const newData = [...data]
            newData[i].value += letter
            return newData
          })
        }, 60)
      }
    }
    await wait(() => {
      setTextFieldData(data => {
        const newData = [...data]
        newData[2].focused = false
        return newData
      })
      updateActiveIcon(3)
    }, 500)
    await wait(() => {
      setActiveButton(true)
    }, 500)
    await wait(() => {
      setActiveIcons([false, false, false])
      setDialogActive(true)
    }, 500)
    await wait(() => {
      setTextFieldData(initValues)
      setActiveButton(false)
      setDialogActive(false)
    }, 4000)
    setTrigger(val => !val)
  }, [trigger])
  React.useEffect(() => {
    processFields()
  }, [processFields])
  const imageData = useStaticQuery(graphql`
    query {
      coffee: file(relativePath: { eq: "coffee.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <figure className="phone">
      <div className="phone-screen">
        <div className={`success-container${dialogActive ? " active" : ""}`}>
          <div className="message-container">
            <div className="icon-container flex-center-center">
              <Icon iconName="check" width={40} />
            </div>
            <div className="message">
              <span className="text-indigo">Payment Received</span>
            </div>
          </div>
        </div>
        <div className="phone-content">
          <div className="nav flex-space-between-center">
            <div className="pa-logo">
              <div>PHILIPS ACADEMY</div>
              <div>FLAVOR HOUSE</div>
            </div>
            <div className="checkout">CHECKOUT</div>
            <div />
          </div>
          <div className="phone-form">
            <div>
              <div className="form-header flex-align-center">
                <div
                  className={`icon flex-center-center${
                    activeIcons[0] ? " active" : ""
                  }`}
                >
                  1
                </div>
                <div className="title">Customer Info</div>
              </div>
              {textFieldData.slice(0, 2).map(({ focused, value }, index) => {
                return (
                  <div className="input-container" key={`input-${index}`}>
                    <PhoneInput
                      label={textFields[index].label}
                      focused={focused}
                      value={value}
                    />
                  </div>
                )
              })}
              <div className="form-header flex-align-center margin-top-2">
                <div
                  className={`icon flex-center-center${
                    activeIcons[1] ? " active" : ""
                  }`}
                >
                  2
                </div>
                <div className="title">Payment Info</div>
              </div>
              <div className="input-container">
                <PhoneInput
                  label={textFields[2].label}
                  focused={textFieldData[2].focused}
                  value={textFieldData[2].value}
                />
              </div>
              <div className="form-header flex-align-center margin-top-2">
                <div
                  className={`icon flex-center-center${
                    activeIcons[2] ? " active" : ""
                  }`}
                >
                  3
                </div>
                <div className="title">Review Order</div>
              </div>
              <div>
                <div className="review-order display-flex">
                  <div className="image-container">
                    <GatsbyImage
                      fluid={imageData.coffee.childImageSharp.fluid}
                    />
                  </div>
                  <div>
                    <h6>Pumpkin Spice</h6>
                    <div>
                      <span>$15.00 x 2</span>
                    </div>
                  </div>
                </div>
                <div className="order-total flex-space-between-center">
                  <div>Order Total</div>
                  <div>$30.00</div>
                </div>
              </div>
            </div>
            <div>
              <div className={`checkout-btn${activeButton ? " active" : ""}`}>
                PLACE ORDER
              </div>
            </div>
          </div>
        </div>
      </div>
    </figure>
  )
}
