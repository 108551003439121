import React from "react"
import SectionHeader from "./SectionHeader"
import ServiceIcon from "./ServiceIcon"
import WaveSVGBottom from "./WaveSVGBottom"

const serviceItems = [
  {
    heading: "Design",
    text:
      "From system architecture to web layouts and imagery, we'll give your brand an effective digital voice...with good looks to boot.",
  },
  {
    heading: "Development",
    text:
      "Our technology is built to industry standards using the latest and greatest software development tools, ensuring your most niche business needs are effectively covered.",
  },
  {
    heading: "Payments",
    text: (
      <>
        We partner with{" "}
        <a href="https://stripe.com" rel="noopener noreferrer" target="_blank">
          Stripe
        </a>{" "}
        and{" "}
        <a
          href="https://squareup.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Square
        </a>{" "}
        to seamlessly and securely manage all of your customer payments and
        revenue streams.
      </>
    ),
  },
  {
    heading: "User Management",
    text:
      "We ensure your customer accounts are handled securely through proper authentication and user management systems.",
  },
  {
    heading: "SEO",
    text:
      "Our tech toolkit and internet expertise will ensure your website is optimized for search engines to enable you to connect with your future customers.",
  },
  {
    heading: "Reporting",
    text:
      "We give you direct access to all your business data with custom reports to help you make informed decisions through meaningful insights.",
  },
]

export default () => {
  return (
    <section className="wave-section wave-bottom">
      <div className="layout-container">
        <SectionHeader
          header="our services"
          id="services"
          text="We offer full-service web and application development for small businesses who need to sell goods or offer services online. We design and build engaging websites for awesome companies, just like yours!"
        />
        <div style={{ margin: "24px auto" }}>
          <div className="grid-container add-width-2">
            {serviceItems.map(({ heading, text }, index) => {
              return (
                <div className="service-grid-item padding-2" key={heading}>
                  <ServiceIcon index={index} />
                  <h5>{heading}</h5>
                  <p>{text}</p>
                </div>
              )
            })}
          </div>
        </div>
        <div className="footer-text">
          <h5>and more...</h5>
          <p>
            We are your long-term technology partner, ready to build and manage
            everything your web platform needs to thrive.
          </p>
        </div>
      </div>
      <div className="wave-svg-container wave-bottom">
        <WaveSVGBottom />
      </div>
    </section>
  )
}
