import React from "react"
import SectionHeader from "./SectionHeader"
import Dashboard from "./Dashboard"
import Phone from "./Phone"
import Tablet from "./Tablet"

export default () => {
  return (
    <section className="wave-padding-section blue-section wave-top wave-bottom">
      <div>
        <SectionHeader header="about us" id="about" />
        <div className="about-us-container">
          <div className="text-content">
            <div className="grid-container add-width-2">
              <div className="grid-item-2 padding-2">
                <h5>We design and build from the ground up</h5>
                <p>
                  Programming all of our sites from scratch means we can meet
                  virtually any business requirement for every project. We use
                  the latest web development tools and frameworks to build fast
                  and custom web applications. That's the biggest benefit of
                  choosing Wavefoundry - if you can dream it, we can build it.
                </p>
              </div>
              <div className="grid-item-2 padding-2">
                <h5>We love what we do, and what you do</h5>
                <p>
                  We're just as passionate about building software as we are
                  about seeing your business thrive. When you choose Wavefoundry
                  as your technology partner, rest assured that we are fully
                  committed to growing your brand and improving your business
                  through our technology.{" "}
                  <a
                    href="https://g.page/wavefoundry?we"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Just ask our clients
                  </a>
                  !
                </p>
              </div>
            </div>
          </div>
          <div className="device-container">
            <div className="tablet-container">
              <div className="device-padding">
                <Tablet />
              </div>
            </div>
            <div className="phone-container">
              <div className="device-padding">
                <Phone />
              </div>
            </div>
          </div>
          <div>
            <Dashboard />
          </div>
          <div>
            <p className="footer-text">
              We are web-savvy technophiles who love building awesome online
              platforms that look great and perform even better.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
