import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

const ShowcaseImages: React.FC<{ leftSide?: boolean }> = ({ leftSide }) => {
  const imageData = useStaticQuery(graphql`
    query {
      tillPhone: file(relativePath: { eq: "till_phone.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dzignStudioPhone: file(relativePath: { eq: "dzign_studio_phone.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      till: file(relativePath: { eq: "till.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dzignStudio: file(relativePath: { eq: "dzign_studio.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className={`showcase-images-container ${leftSide ? "left" : "right"}`}>
      <div className="browser-container">
        <div className="toolbar">
          <div className="buttons flex-align-center">
            <div>
              <span className="browser-button red" />
            </div>
            <div>
              <span className="browser-button yellow" />
            </div>
            <div>
              <span className="browser-button green" />
            </div>
          </div>
        </div>
        <div>
          <GatsbyImage
            fluid={
              leftSide
                ? imageData.till.childImageSharp.fluid
                : imageData.dzignStudio.childImageSharp.fluid
            }
          />
        </div>
      </div>
      <div className="mobile-container">
        <GatsbyImage
          fluid={
            leftSide
              ? imageData.tillPhone.childImageSharp.fluid
              : imageData.dzignStudioPhone.childImageSharp.fluid
          }
        />
      </div>
    </div>
  )
}

export default ShowcaseImages
